@import url("https://fonts.googleapis.com/css2?family=Protest+Revolution&display=swap");

*{
  font-family:Protest Revolution;
  margin: none;
  padding: none;
  }
  
.octagon {
    clip-path: polygon(25% 0%, 75% 0%, 100% 25%, 100% 75%, 75% 100%, 25% 100%, 0% 75%, 0% 25%);
  }
  
  .irregular-polygon {
    clip-path: polygon(
      25% 3%, 75% 3%, 90% 25%, 95% 50%, 90% 75%,
      65% 85%, 35% 85%, 10% 75%, 5% 50%, 10% 25%
    );
  }
  .relative {
    position: relative;
  }
  
  .absolute {
    position: absolute;
    top: 0;
    left: 0;
  }

  .video-container {
    margin: 5% 10%; /* Margin from all sides */
    position: relative;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    overflow: hidden;
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  
  .video-container iframe.visible {
    opacity: 1;
  }

  @keyframes moveRightToLeft {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  
  .animate-moveRightToLeft {
    animation: moveRightToLeft 5s linear infinite; /* Adjust animation duration as needed */
  }
  

 
  